@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.ch_check {
  padding: 16px;
  background: var(--bg-second);
  border-radius: 12px;

  &__top {
    display: flex;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    background: var(--bg-body);
    border-radius: 12px;
    &_description {
      position: relative;
      margin: 0;
      text-align: center;
      color: var(--text-100);
      font: $footnote-mobile;
    }
  }

  &__bot {
    display: flex;
    align-items: center;
    &_label {
      margin-bottom: 2px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--text-80);
    }
    &_description {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-100);
      & > span {
        position: relative;
        color: $action;
        cursor: pointer;
        white-space: nowrap;
        opacity: 1;
        transition: opacity 0.2s ease;
        &:after {
          position: absolute;
          transform-origin: 0 50%;
          content: '';
          width: 85px;
          left: 0;
          bottom: 0;
          border-bottom: 1px solid var(--action);
          transform: scaleX(0);
          transition: transform 0.2s ease;
        }
        &:hover:after {
          transform: scaleX(1);
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
  &_btn_approve {
    margin-top: 32px;
    border-radius: 8px !important;
  }

  &_skip {
    text-align: center;
    margin: 24px 0 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-100);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
