@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.ns_container {
    display: flex;
    flex-direction: column;

    @include is-mobile() {
        width: 100%;
        padding: 0 16px;
    }
}

.ns_lenza_icon {
    align-self: center;
    width: 72px;
    height: 72px;
    path {
        fill: #6A7DFD;
    }

    @include is-mobile() {
        margin-bottom: 22px;
    }
}

.ns_title {
    margin: 24px 0;
    padding: 0;
    border: none;

    h2 {
        color: var(--text-100)
    }
}

.ns_description_wrapper {
    .ns_description {
        padding: 16px 0;
        margin: 0;
        border: none;

        p {
            color: var(--text-80);
        }
    }  

    @include is-mobile() {
        .ns_description_divider {
            margin: 24px 0;
        }
    }
}

.ns_create_new_ws {
    border-radius: 16px;
    &_web {
        padding: 0 !important;
    }

    .list-item__icon-main-wrapper {
        background-color: var(--bg-second);
        border-radius: 12px;

        .icon-wrapper {
            width: 17.5px;
            height: 17.5px;
        }
    }
 
    @include is-mobile() {
        border: 1px solid var(--stroke-opacity);
    }
}

.ns_back__link {
    align-self: center;
    margin: 44px 0 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-100);
    @include is-mobile() {
      color: var(--text-100);
      margin: 24px 0 0;
    }
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
}