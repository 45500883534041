@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';


.ap_domains_body {
  padding: 16px 24px 0;
  flex-grow: 1;
}

.ap_domains_footer {
  width: 100%;
}

.ap_domains {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ap_domains_info {
  background: var(--bg-second);
  border-radius: 12px;
  padding: 16px;
}

.ap_domains_info_top {
  background: var(--bg-body);
  padding: 16px;
  border-radius: 12px;
}

.ap_domains_footer {
  padding: 16px 24px;
}

.ap_domains_info_icon span{
  font: $dialog-mobile-small;
}

.ap_domains_info_text span {
  font: $body-mobile
}

.ap_domains_info_bottom span {
  font: $body-mobile
}