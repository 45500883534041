@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.card-contact {
  &-component {
    width: 172px;
    height: 240px;
    box-shadow: var(--ui-shadow-card-rest);
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    @include is-not-mobile() {
      &:before {
        content: '';
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid;
        border-color: var(--stroke-opacity);
        left: 0;
        top: 0;
        z-index: 11;
      }
    }

    &_card-view {
      width: 172px;
      height: 218px;
      border-radius: 12px;
      border: 1px solid var(--stroke-opacity);

      @include is-mobile() {
        box-shadow: none;
      }
    }
  }

  &-avatar {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 146px;

    .avatar--full {
      width: 100%;
      height: 100%;
      border-radius: 0;

      .avatar__img-wrapper {
        background-size: cover !important;
        border-radius: 0;
        background-position: center;
      }
    }

    &-component {
      & > div > div {
        background-size: cover !important;
      }
    }
  }

  &-friend {
    &-avatar {
      cursor: default;
    }
  }

  &-title {
    height: 40px;
    margin-bottom: 4px;

    @include is-mobile() {
      span {
        font: $body-bold-mobile;
      }
    }

  }

  &-content {
    padding: 16px;
  }

  &-selected, &-friend {
    &:before {
      border: 2px solid var(--action) !important;
    }
  }

  &-checkbox.checkbox, &-tag {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 11;
  }

  &-tag {
    background: var(--bg-body);
    border-color: var(--stroke-opacity);
    box-shadow: unset;

    svg path {
      color: var(--text-80);
    }
  }

}

.card-contact-component_view-list {
  min-height: 64px !important;
  height: 64px !important;


  .list-item__title {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
  }

  .list-item__info-text{
    font-size: 15px;
  }
}