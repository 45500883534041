@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

body.theme-4:not(.is-mobile) {
  .d_domain {
    &__wrapper {
      & .inp_auth_input {
        & .form-field__description {
          color: var(--text-left-80);
        }
      }
    }
  }
}

body.theme-2:not(.is-mobile) {
  .d_domain {
    &__wrapper {
      & .inp_auth_input {
        & .form-field__error {
          color: var(--text-left-80);
        }
      }
    }
  }
}

.d_domain {
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 520px;
  flex-direction: column;

  &_header {
    border: none;
    padding: 0;
    h2 {
      color: var(--text-100);
    }
    p {
      color: var(--text-80);
    }
  }

  .d_body_text {
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  .d_loader_wrapper {
    .loading {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
    }
  }
  &__wrapper {
    width: 100%;
    max-width: 400px;

    .layout-column {
      margin-bottom: 32px;
    }
  }
  &__subtitle {
    margin-bottom: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--text-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }
  &__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  &__input {
    display: flex;
    align-items: center;
    border: 1px solid var(--action);
    border-radius: 4px;
    background-color: var(--bg-second);
    padding: 10px 12px;
    width: 100%;
    cursor: text;
    &_element {
      border: none;
      font-weight: 400;
      width: 100%;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-100);
      background-color: transparent;
      &::placeholder {
        color: var(--text-60);
      }
    }
    &_description {
      margin: 4px 0 19px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
  }
  &__back-link {
    font: $body;
    color: var(--text-100);
    cursor: pointer;
    margin: 24px auto 0;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
  &__show_link {
    padding: 10px 12px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    overflow: hidden;
    line-height: 20px;
    color: var(--text-60);
    background-color: var(--no-active);
    border: 1px solid;
    border-color: var(--stroke-opacity);
    border-radius: 4px;
  }
}

.d_error_message {
  margin: 4px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $error;
}

.d_error {
  border: 1px solid $error;
}
.d_loader {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  box-shadow: none !important;
  background: none !important;
  &_wrapper {
    margin-left: 4px;
  }
}
.d_cancel_icon {
  cursor: pointer;
}

.d_success_icon {
  & > path {
    fill: $success;
  }
}

.d_body {
  width: 100%;
  flex-grow: 1;
  padding: 24px;
}
.d_footer {
  width: 100%;
  padding: 16px;
}



.inp_auth_input_label {
  font-weight: 400;
  color: var(--text-100);
}

.inp_auth_input {
  .input__label {
    height: auto;
    max-height: max-content;

    .header-left {
      max-width: 100%;
      p {
        color: var(--text-80);
        font: $footnote;
      }
    }
  }
}