@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.inu_invite {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 100%;

  .invite-users-in-company-component {
    width: 100%;

    .invite-company__main-content .component-tags-row-focus.invite-company__input-emails {
      min-height: auto;
      border-radius: 8px;
      box-shadow: none;
    }

    & span {
      @include is-mobile() {
        color: var(--text-100);
      }
    }
  }

  &__heading {
    margin: 0 0 24px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-100);
  }

  &__description {
    margin: 0 0 18px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
    }
  }
  &__wrapper {
    margin-bottom: 42px;
    width: 100%;

    .component-tags-row-focus {
      .tags-row-tag:not(.tags-row-tag__error) {
        background: var(--bg-body);
      }
    }

    .tags-row-finish {
      background-color: var(--bg-second);
    }
  }

  &__wrapper_success {
    & > div:first-child {
      overflow-y: scroll !important;
      scrollbar-width: none !important;
      -ms-overflow-style: none !important;
      max-height: 250px !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
  &__row {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: 250px !important;
    &::-webkit-scrollbar {
      display: none;
    }
    & div:last-child {
      min-width: 100%;
    }
  }
  &__error {
    display: flex;
    align-items: center;
    margin: 18px 0 20px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
    }

    svg {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    &_icon {
      color: $error;
      margin-right: 6px;
    }
    &_delete {
      color: var(--action);
      margin: 0 0 0 4px;
      position: relative;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s ease;
      &:after {
        position: absolute;
        transform-origin: 0 50%;
        content: '';
        width: 189px;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid var(--action);
        transform: scaleX(0);
        transition: transform 0.2s ease;
      }
      &:hover:after {
        transform: scaleX(1);
      }
      &:active {
        opacity: 0.5;
      }
    }
  }
  &__buttons {
    width: 100%;

    & > button {
     & .btn__title {
      color: var(--text-left-100) !important;
       @include is-mobile() {
         color: var(--text-100) !important;
       }
     }
    }

    .btn {
      border-radius: 8px;
    }
  }

  &__link_skip {
    text-align: center;
    margin: 24px 0 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-100);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @include is-mobile() {
    width: 100%;
  }
}

.inu_invite_header_sub {
  justify-content: center;
}

.inu_invite_start_body {
  margin-top: 12px;
  padding: 12px 24px;
  flex-grow: 1;
}

.inu_invite_success_body {
  padding: 24px;
  flex-grow: 1;
}

.inu_invite_footer {
  width: 100%;
  padding: 16px 24px;
}

.invite-company__button {
  border: 1px solid var(--stroke-opacity) !important;
  gap: 4px;
  .btn__title {
    width: auto;
  }
}

.inu_invite_start_copy_link {
  background: initial !important;

  .btn__title {
    color: var(--action);
  }

  .icon {
    color: var(--action);
  }

  border: 1px solid var(--action) !important;
}

.invite-company__mobile-title {
  height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;
  min-height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;

  @include is-ios() {
    padding-top: 4px !important;
  }
}

.inu_check {
  &_title {
    border: none;
    padding: 0;
    h2 {
      color: var(--text-100);
    }
  }
}

#dialog-root {
  .component-dialog--center {
    justify-content: flex-start;
  }
  .component-dialog--modal {
    background: transparent;
    top: 48px;
    @include is-mobile() {
      top: 0;
    }
  }

  .component-dialog--center .component-dialog__inner {
    min-width: auto;
    width: 480px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px #0000000A;
  }

  .component-dialog .component-dialog__content-padding {
    padding: 32px;
  }

  .component-dialog .component-dialog-title {
    @include is-not-mobile() {
      display: none;
    }
  }

  .success-invite-modal__container {
    padding: 0;

    .success-invite-modal__emails-container {
      .layout-gap-xsm {
        padding: 10px 0;
      }
    }
  }
}