@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.j_join {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__avatar {
    margin-bottom: 24px;
  }
  &__heading {
    border: none;
    margin: 0;
    .hdi_title, .j_company_name {
      color: var(--text-100);
    }
    .hdi_description {
      color: var(--text-80);
    }
  }
  &__bottom_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;

    .btn--full-width {
      border-radius: 8px;
    }
  }
  &__input {
    margin-bottom: 16px;

    .inp_title {
      color: var(--text-80);
    }

    .form-item {
      input {
        border-radius: 8px;
        border: var(--border-adaptive) solid var(--stroke-opacity) !important;
      }
    }

    .is-invalid {
      text-align: center;
    }

    & .form-field__error {
      color: var(--red-1);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
  }

  @include is-mobile() {
    width: 100%;
    &__bottom_wrapper {
      width: 100%;
    }
  }
}

.j_company_name {
  color: var(--text-left-100);
  @include is-mobile() {
    color: var(--text-100);
  }
}


