@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.p_main {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding-bottom: 24px;
  overflow-y: auto;

  .form-item input, .component-tags-row-focus {
    background-color: var(--bg-second);
  }

  @include is-mobile() {
    padding: 0;
  }
}

.main__header_web {
  padding-top: 48px;

  button {
    &:hover {
      background: var(--bg-hover) !important;
    }
    &:focus {
      background: var(--stroke-opacity) !important;
    }
  }

  .lang-switch:not(.is-mobile) {
    background: var(--bg-body);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 10px 24px;

    span {
      color: var(--text-80);
    }
  }
}




