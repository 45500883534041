@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.addf_friends {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;


  &__heading {
    padding: 0 0 24px 0;
    margin-top: 32px;
    border-bottom: 1px solid;
    border-color: var(--stroke-opacity);
    width: 100%;
    @include is-mobile() {
      margin-bottom: 24px;
    }
  }

  &__title {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-100);
    @include is-mobile() {
      margin: 0 0 16px 0;
    }
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--text-80);
  }

  &_scroll_bar {
    // max-height: 404px;
    width: 100%;
    padding: 0 32px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: clip;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--text-60);
      border-radius: 12px;
    }
    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: var(--text-60) transparent;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 1072px;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 32px;
    width: 100%;
    border-top: 1px solid;
    border-color: var(--stroke-opacity);

    @include is-mobile() {
      bottom: var(--safe-area-bottom);
    }

    .btn {
      border-radius: 8px;
    }
    .btn:last-child {
      background-color: #5B53CC;
    }
  }

  &__checkbox_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1072px;
    gap: 8px;
    user-select: none;
    margin: 20px 0;
  }

  &__button_skip {
    margin-right: 8px;
  }

  @include is-mobile() {
    &__footer {
      flex-wrap: wrap;

    }
    &__button_skip {
      margin: 0;
    }

    &__button_add {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.add_friends_mob {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__filter {
    padding: 8px 16px;
  }

  &__select-all {
    .btn__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .switcher {
        pointer-events: none;
      }
    }

    background: var(--bg-second) !important;
    border-radius: 12px !important;
    padding: 11px 16px !important;
    width: 100%;
    border: none !important;
  }

  &__contact-list {
    max-height: calc(100vh - 124px - var(--android-mobile-header-height) - var(--safe-area-top) - 140px - var(--safe-area-bottom));

    @include is-ios() {
      max-height: calc(100vh - 124px - var(--ios-mobile-header-height) - var(--safe-area-top) - 140px - var(--safe-area-bottom));
    }

    flex-grow: 1;
    display: flex;
    flex-flow: wrap;
    overflow-y: auto;
    padding: 4px 16px 0;

    &_list-view {
      padding: 4px 0 0;
    }

    &:not(.add_friends_mob__contact-list_list-view) {
      justify-content: center;
    }

    &.add_friends_mob__contact-list_list-view {
      flex-direction: column;
    }
  }

  &__loader {
    width: 100%;
    padding-top: 30px;
  }

  &__footer {
    padding: 16px;
  }
}

