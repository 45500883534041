@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';

.cb_company {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  // background-image: url('../../assets/images/backgroundBanner.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  flex-grow: 1;

  &__heading {
    border: none;

    padding: 0;
    margin: 0 0 24px 0;

    @include is-mobile() {
      width: 100%;
      padding: 16px 16px 0;
      border: none;
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-100);

    &_company {
      white-space: nowrap;

      @include is-mobile() {
        white-space: unset;
      }
    }

    & > span {
      color: var(--text-100);
      @include is-mobile() {
        color: var(--text-100);
      }
    }

    @include is-mobile() {
      color: var(--text-100);
      font: $dialog-title;
      margin-bottom: 16px;
    }
  }

  &__description {
    margin: 8px 0 0 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--text-80);

    @include is-mobile() {
      color: var(--text-80);
      margin-top: 0;
      font-size: 17px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;

    .btn {
      background: #5B53CC;
      border-radius: 8px;
      &:hover {
        opacity: .92;
      }
    }

    &_title {
      margin: 0 0 16px 0;
      font: $dialog-title;
      text-align: center;
      color: var(--text-100);

      @include is-mobile() {
        color: var(--text-100);
      }
    }

    &_description {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }

    @include is-mobile() {
      padding: 16px;
      flex-grow: 1;
    }
  }

  &__submit {
    padding: 16px;
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 16px;
    border: 1px solid var(--action);
    background: rgba(var(--action-rgb), 0.08);
    border-radius: 8px;

    @include is-mobile() {
      border-radius: 12px;
    }
  }

  &__shield_icon {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    path:first-child {
      fill: var(--action);
    }
    path:last-child {
      fill: #fff;
    }
  }

  &__list {
    margin: 0 0 12px 0;
    padding: 0 0 0 16px;

    &_item {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-100);
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      @include is-mobile() {
        color: var(--text-100);
        font-size: 17px;
      }
    }
  }

  @include is-mobile() {
    background-image: none;
  }
}

