@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';

.modal__description {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-100);
  margin-bottom: 16px;

  @include is-mobile() {
    padding: 8px 0;
    color: var(--text-80);
  }
}

.modal__form_title {
  margin: 0 0 7px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-80);

  @include is-mobile() {
    font: $body-bold;
    color: var(--text-100);
  }
}


.send-first-message-for-friends {
  .hdi_container {
    border: none;
    padding: 0;
    h2 {
      color: var(--text-100);
      margin: 0 0 8px 0;
      width: 100%;

      .sfm__title-block {
        display: flex;
        align-items: center;
        position: relative;
        .btn_back {
          fill: var(--text-80);
          font-size: smaller;
          position: absolute;
          cursor: pointer;
        }
        &__text {
          margin: 0 auto;
        }
      }
    }
    p {
      color: var(--text-80);
    }
  }

  .form-item textarea {
    border-radius: 8px;
    max-height: 120px;
    scrollbar-width: none;
    &:focus {
      border-color: #5B53CC !important;
      box-shadow: none !important;
    }
  }

  .dialog-buttons-wrap {
    padding: 0 32px 32px;
    .dialog-buttons-wrap-center {
      display: flex;
      flex-direction: column;
      gap: 7px;
      .btn:first-child {
        border-radius: 8px;
        background-color: #5B53CC;
      }
    }
  }

  & .mobile-header {
    height: 10%;
    // height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;
    min-height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;

    @include is-ios() {
      height: 10%;
      // height: calc(var(--ios-mobile-header-height) + var(--safe-area-top)) !important;
      min-height: calc(var(--ios-mobile-header-height) + var(--safe-area-top)) !important;
    }
  }
}