@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.logo_icon {
  width: 40px;
  height: 32px;
  @include is-mobile() {
    height: 30px;
  }
}

.logo {
  display: flex;
  gap: 12px;
  align-items: center;
}

.logo_text {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
}