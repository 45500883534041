.pr_slider_item {
  //width: 100vw;
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}

.pr_slider_item_web {
  gap: 32px;
}

.pr_container {
  width: 100vw;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  //padding-bottom: 114px;
  //overflow-x: hidden;
}

.pr_container__web {
  width: auto;
  padding: 0;
}

.pr_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}

.pr_slider_image {
  width: 320px;
  height: 233px;
  border-radius: 12px;
  overflow: hidden;
}

.pr_slider_image_web {
  width: 416px;
  height: 232px;
  object-fit: cover;
}

.pr_slider_info {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  p {
    text-align: center;
  }
}

.pr_slider_info_web {
  gap: 8px;
  height: 116px;
}

.pr_slider_title {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
}

.pr_slider_title_web {
  font-weight: 600;
}

.pr_slider_description {
  line-height: 22px;
  font-size: 17px;
}

.pr-slider_header {
  display: none !important;
}

.pr_slider_position {
  height: 15px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  & > div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #D9D9D9;

    &.pr_active_slide {
      background: var(--action);
    }
  }
}

.pr_footer {
  padding: 16px;
}

.pr_footer_web {
  padding: 16px 0 0;
}

.pr-slider {
  flex-grow: 1;
  height: auto !important;

  & > div {
    height: auto;
  }

  & > div:nth-child(2) {
    max-height: 100%;
    height: 100%;
  }
}

.pr-slider_web {
  padding-bottom: 16px;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.pr-slider_web_show {
  opacity: 1;
}
