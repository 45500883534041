@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.lang-switch {
  @include is-mobile() {
    border-radius: 8px !important;
  }

  &.btn {
    .icon:first-child:not(:only-child) {
      margin-right: 0;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 0;

      @include is-mobile() {
        width: 20px;
        height: 20px;
      }

      svg {
        width: 100%;
        height: 100%;

        path {
          @include is-mobile() {
            color: var(--text-100) !important;
          }
        }
      }
    }

    .component-text.text-type-primary {
      color: var(--text-left-80);
      font: $metadata;

      @include is-mobile() {
        color: var(--text-100);
        font-size: 15px;
        line-height: 20px;
        font-weight: 700;
        padding: 0;
      }
    }

    .icon:last-child:not(:only-child) {
      margin-left: 4px;
    }

    @include is-mobile() {
      .btn__title {
        & > div {
          gap: 8px !important;
        }
      }
    }
  }
}
