@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.f_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.f_card {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 400px;
  background: var(--bg-second);
  border: 1px solid;
  border-color: var(--stroke-opacity);
  border-radius: 4px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include is-mobile() {
    width: 100%
  }
}
.f_img {
  margin-right: 16px;
  height: 48px;
  width: 48px;
  border-radius: 4px;
}
.f_container {
  display: block;
  margin-right: 16px;
  width: 272px;
}
.f_heading {
  margin: 0 0 4px 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-80);
}
.f_description {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-100);
}
.f_icon {
  width: 16px;
  height: 16px;
}

.f_heading_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  border-color: var(--stroke-opacity);
  flex-direction: column;
  padding: 0 0 24px 0;
  max-width: 520px;
}

.f_heading_main {
  margin-bottom: 16px;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--text-100);
}
.f_description_main {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--text-80);
}


