.d_divider {
    display: flex;
    align-items: center;
    justify-content: space-between;

    hr {
        width: 50%;
        border: 1px solid var(--stroke-opacity);
    }

    p {
        margin: 0 16px;
    }
}