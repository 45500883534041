.wrapper-web {
    margin-top: 48px;
    max-width: 480px;
    background: var(--bg-body);
    box-shadow: 0px 2px 4px 0px #0000000A;
    border-radius: 12px;
    padding: 32px;

    &_fullWidth {
        max-width: 1344px;
        max-height: calc(100vh - 96px);
        width: calc(100vw - 96px);
        padding: 0;
    }
}