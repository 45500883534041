@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.auth-privacy {
  margin-top: auto;
  padding-top: 24px;

  &-link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .text-privacy {
    text-align: center;
    color: var(--text-left-100) !important;

    @include is-mobile() {
      color: var(--text-100) !important;
    }
  }
}

.dialog-auth-privacy {
  &.component-dialog .component-dialog__content {
    color: var(--text-100);
    @include is-mobile() {
      color: var(--text-100) !important;
    }
    .scrollbar-component {
      p {
        margin-bottom: 20px;
      }
    }
  }

  &_title {
    display: flex;
    align-items: center;
    gap: 20px;
    svg {
      cursor: pointer;
    }
  }
  .component-dialog-title {
    display: flex !important;

    .component-dialog-title-buttons {
      display: none;
    }
  }

  .component-dialog__inner {
    .component-dialog__content-padding {
      padding: 0 !important;
      max-height: 600px;

      .scrollbar-container {
        right: 0px;
      }
    }
  }
}
