@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';


.ws_workspace {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 416px;
  @include is-mobile() {
    padding-bottom: 100px;
    min-width: auto;
  }
  &.pointerEvents {
    pointer-events: none;
  }

  &_header {
    padding: 0;
    border: none;
    h2 {
      color: var(--text-100);
    }
    p {
      color: var(--text-80);
    }
    @include is-mobile() {
      padding: 4px 8px;
    }
  }

  &_scroll_bar {
    max-height: 488px;
    overflow-y: auto;
    overflow-x: clip;
    margin-right: -38px;
    padding-right: 38px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--text-60);
      border-radius: 12px;
    }
    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: var(--text-60) transparent;
    }
  }

  &_label {
    width: 100%;
    padding: 12px 16px;
    font: $body-mobile;
    color: var(--text-80);
    text-align: left;
    background: var(--bg-second);
  }

  &__actions {
    @include is-mobile() {
      .list-item {
        .list-item__icon-main-wrapper {
          width: 48px;
          height: 48px;
          border-radius: 12px;
          background-color: var(--bg-second);
          margin-right: 16px;
        }
      }
    }
  }

  & .mobile-header {
    background: var(--bg-body);
  }

  &__accounts_wrapper {
    padding: 8px 0;
    border-radius: 12px;
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 520px;
    padding: 0 0 24px 0;
    margin: 0 0 27px 0;
    border-bottom: 1px solid;
    border-color: var(--stroke-opacity);
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-100);
  }

  &__accounts {
    padding: 0;
    width: 400px;
    border-radius: 6px;

    .btn {
      border-radius: 8px;
    }

    @include is-not-mobile() {
      .list-item {
        padding: 8px 4px 8px 3px;
      }
     }

    & .list-item {
      @include is-mobile() {
          .list-item__avatar {
            width: 48px;
            height: 48px;
            margin-right: 16px;
            .avatar__img-wrapper {
              border-radius: 12px;
            }
          }

        .list-item__title {
          font-size: 17px;
          font-weight: 700;
          line-height: 20px;
        }

        .list-item__info-text {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }



        button {
          border: none;
          border-radius: 8px;

          &:not(.btn--action) {
            background-color: var(--surface-1);

            .btn__title {
              color: var(--text-80);
            }
          }
        }
      }
    }

    & + .ws_workspace__accounts {
      margin-top: 16px;
    }

    &_invite-resume {
      span {
        font-weight: 600;
      }
    }

    &_new_item {
      .list-item__icon-main-wrapper {
        height: 48px !important;
        width: 48px !important;
        background: var(--bg-second);
        border-radius: 12px;
      }

      .list-item__right {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_domain_title {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 15px;
      @include is-mobile() {
        padding-left: 16px;
        p {
          padding: 0;
          font-weight: 400;
        }
      }
    }

    &_title {
      @include is-mobile() {
        padding-left: 16px;
      }
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--text-80);

      display: inline-flex;
      align-items: center;
      white-space: pre-wrap;

      & > span {
        font-weight: 600;
      }

      svg {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-right: 4px;

        path {
          color: var(--text-80);
        }
      }

      @include is-mobile() {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: var(--text-100);
      }
    }

    &_extra_btns {
      padding: 8px 0;
      background-color: var(--bg-second);
      border-radius: 4px;
    }

    @include is-mobile() {
      background: var(--bg-body);
    }
  }

  &__text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    @include is-not-mobile() {
      color: var(--text-left-80);
    }
  }

  @include is-mobile() {
    &__accounts {
      width: 100%;
    }

    background: var(--bg-second);
  }
}

