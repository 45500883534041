@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';


.inv_invalid {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  width: 416px;
  @include is-mobile() {
    height: 100vh;
  }

  &_lenza_icon {
    align-self: center;
    width: 72px;
    height: 72px;
    path {
        fill: #6A7DFD;
    }

    @include is-mobile() {
        margin-bottom: 22px;
    }
  }

  &__heading {
    margin: 0;
    font: $dialog-title;
    text-align: center;
    color: var(--text-100);
    @include is-mobile() {
      color: var(--text-100);
    }
  }

  &__description {
    margin: 0;
    max-width: 560px;
    font: $body;
    text-align: center;
    color: var(--text-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }

  &_back__link {
    align-self: center;
    margin: 36px 0 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-100);
    @include is-mobile() {
      margin: 24px 0 0;
    }
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
}
}
