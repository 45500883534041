@import '../../fonts';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/style';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import './reset.scss';


body > iframe {
  display: none;
}

a {
  text-decoration: none;
  color: var(--text-100);

  &:hover, &:active {
    color: var(--text-100);
  }
}

#tooltip-root {
  pointer-events: none;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  color: var(--text-100);
}

p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--text-100);
  overflow: hidden;
  background: var(--bg-second);

  padding-top: var(--safe-area-top);
  padding-bottom: var(--safe-area-bottom);


  .component-base-context-menu.is-inner-element {
    box-shadow: $ui-shadow-dark-flyout;
    background: var(--bg-second);
    position: absolute;
  }

  .error-page-component {
    flex-grow: 1;
  }

  @include apply-styles-for-themes((1,2,3,4), true, light) {
    background-color: white;
  }

  @include apply-styles-for-themes((1,2,3,4), true, dark) {
    background-color: rgba(32, 31, 30, 1);
  }

  &.is-inner-iframe {
    height: 100%;
    min-height: 100%;

    padding: 0;
  }
}

#dialog-root, #context-root {
  color: var(--text-100);
}

.scrollbar-invisible {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}

.scrollbar-default {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    height: 35px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner, &::-webkit-resizer {
    background-color: var(--text-100);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--text-60);
  }

  overflow: hidden;
  overflow-y: auto;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.popular-sites-layout {
  .card-item {
    &:not(.card-item-in-one-line) {
      height: 100%;
    }
  }

  .card-item__icon-wrapper {
    border-radius: 4px;
    overflow: hidden;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

#root, #dialog-root {
  .more-list-layout {
    padding: 4px 0 16px 0;
    flex-wrap: wrap;
    gap: 4px;
  }
}

.search-list-layout {
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
}

.hotkeys {
  background-color: var(--bg-hover);
  box-shadow: inset 0 1px 0 var(--stroke-opacity);
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.no-drag {
  -webkit-app-region: no-drag;
}

.loading svg path {
  fill: var(--text-100);
}

#alert-root {
  display: none;
  position: relative;
  z-index: 10;

  &.active {
    display: block;
  }

  button {
    display: none;
  }
}

.text-error {
  color: $error;
}

.component-text {
  .blue {
    color: var(--action) !important;
  }
}

.blend-mode-blur {
  opacity: 0.5;
  mix-blend-mode: luminosity;

  .header-electron  {
    &__left, &__center, &__right {
      mix-blend-mode: luminosity;
    }
  }
}
