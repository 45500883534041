@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.error-page {
  height: calc(100vh - var(--height-web-header));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &_icon {
    width: 72px;
    height: 72px;
    path {
        fill: #6A7DFD;
    }

    @include is-mobile() {
      position: absolute;
      top: 150px;
    }
  }

  & .error-page-component {
    height: 100%;
    width: 100%;
    background-image: none;
    padding: 0;

    .layout-gap-lg {
      gap: 8px;
    }

    .error-page-component-title {
      font: $dialog-title;
    }

    .ui-btn {
      margin-top: 48px;
      width: 100%;
      border-radius: 8px;
      background-color: #5B53CC;
    }
  }

  @include is-mobile() {
    height: 100%;

    & .error-page-component {
      height: calc(100% - var(--android-mobile-header-height));
      padding: 16px;
    }
  }

  @include is-ios() {
    & .error-page-component {
      height: calc(100% - var(--ios-mobile-header-height));
    }
  }
}